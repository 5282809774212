// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-create-password-js": () => import("./../../../src/pages/createPassword.js" /* webpackChunkName: "component---src-pages-create-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-create-password-js": () => import("./../../../src/templates/create-password.js" /* webpackChunkName: "component---src-templates-create-password-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

